<script setup>
  import zindagiPopup from './compontent/zindagi-popup.vue'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
</script>

<template>
  <div class="page-content">
    <zindagiPopup></zindagiPopup>
  </div>
</template>

<style lang="less" scoped>
  .page-content {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 155px - 170px);
  }
</style>
